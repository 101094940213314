export class LoadOrders {
  public static readonly type = "[Reefer mechanic] load orders";
  constructor(
    public employerId: number
  ) {}
}

export class LoadMechanicAssignments {
  public static readonly type = "[Reefer mechanic] load mechanic assignments";
  constructor(
    public orderId: string
  ) {}
}

export class AssignMechanic {
  public static readonly type = "[Reefer mechanic] assign mechanic";
  constructor(
    public orderId: string,
    public workerId: number
  ) {}
}

export class UnassignMechanic {
  public static readonly type = "[Reefer mechanic] unassign mechanic";
  constructor(
    public orderId: string,
    public workerId: number
  ) {}
}

export class SetCommentAssignment {
  public static readonly type = "[Reefer mechanic] set comment assignment";
  constructor(
    public orderId: string,
    public workerId: number,
    public comments: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}