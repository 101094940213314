import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { MechanicAssignment } from "src/app/features/reefer-mechanic/interfaces/mechanic-assignment.interface";
import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { ReeferMechanicService } from "../services/reefer-mechanic.service";
import { AssignMechanic, LoadMechanicAssignments, LoadOrders, SetCommentAssignment, UnassignMechanic } from "./reefer-mechanic.actions";
import { ReeferMechanicStateModel } from "./reefer-mechanic.model";

@State<ReeferMechanicStateModel>({
  name: "reeferMechanic",
  defaults: {
    orders: [],
    assignments: [],
    loading: false
  }
})
@Injectable()
export class ReeferMechanicState {
  
  constructor(
    private reeferMechanicService: ReeferMechanicService
  ) {}

  @Selector() static orders(state: ReeferMechanicStateModel) { return state.orders; }
  @Selector() static assignments(state: ReeferMechanicStateModel) { return state.assignments; }
  @Selector() static loading(state: ReeferMechanicStateModel) { return state.loading; }

  @Action(LoadOrders)
  loadOrders(ctx: StateContext<ReeferMechanicStateModel>, action: LoadOrders) {
    ctx.patchState({
      orders: [],
      assignments: [],
      loading: true
    });
    
    return this.reeferMechanicService.getOrders(action.employerId).pipe(
      tap(
        (response) => {
          const itemsAsOptions: SelectOption[] = response.orders.map(
            (e) => {
              return {
                text: e.name,
                value: e.id
              };
            }
          )

          ctx.patchState({
            orders: itemsAsOptions,
            loading: false
          });
        },
        (error) => {
          ctx.patchState({ loading: false });
        }
      )
    );
  }

  @Action(LoadMechanicAssignments)
  loadMechanicAssignments(ctx: StateContext<ReeferMechanicStateModel>, action: LoadMechanicAssignments) {
    ctx.patchState({
      assignments: [],
      loading: true
    });
    
    return this.reeferMechanicService.getMechanics(action.orderId).pipe(
      tap(
        (response) => {
          ctx.patchState({
            assignments: response.workers,
            loading: false
          });
        },
        (error) => {
          ctx.patchState({ loading: false });
        }
      )
    );
  }

  @Action(AssignMechanic)
  assignMechanic(ctx: StateContext<ReeferMechanicStateModel>, action: AssignMechanic) {
    return this.reeferMechanicService.assignMechanic(action.orderId, action.workerId).pipe(
      tap(
        (response) => {
          const state = ctx.getState();
          ctx.patchState({
            assignments: [response, ...state.assignments],
          });
        }
      )
    );
  }

  @Action(UnassignMechanic)
  unassignMechanic(ctx: StateContext<ReeferMechanicStateModel>, action: UnassignMechanic) {
    return this.reeferMechanicService.unassignMechanic(action.orderId, action.workerId).pipe(
      tap(
        (response) => {
          const state = ctx.getState();
          ctx.patchState({
            assignments: state.assignments.filter((m) => { return m.worker.id !== action.workerId; }),
          });
        }
      )
    );
  }

  @Action(SetCommentAssignment)
  setCommentAssignment(ctx: StateContext<ReeferMechanicStateModel>, action: SetCommentAssignment) {
    return this.reeferMechanicService.setCommentAssignment(action.orderId, action.workerId, action.comments).pipe(
      tap(
        (response) => {
          const state = ctx.getState();
          const assignments: MechanicAssignment[] = [...state.assignments].map((a) => {
            if (a.worker.id === action.workerId) {
              let updatedItem: MechanicAssignment = {...a};
              updatedItem.comments = action.comments;
              return updatedItem;
            }
            return a;
          });
          ctx.patchState({
            assignments: assignments
          });
          action.callbackSuccess();
        },
        (error) => {
          action.callbackError();
        }
      )
    );
  }

}
