import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentService } from "src/app/core/services/environment.service";
import { API_URL } from "src/environments/settings";

@Injectable({ providedIn: "root" })
export class ReeferMechanicService {
  
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getOrders(employerId: number) {
    return this.http.get<any>(`${this.apiUrl}/app/laborOrder/laborOrdersReeferMechanic/${employerId}`);
  }

  getMechanics(id: string) {
    return this.http.get<any>(`${this.apiUrl}/app/laborOrder/${id}/mechanics`);
  }

  assignMechanic(laborOrderId: string, workerId: number) {
    return this.http.post<any>(`${this.apiUrl}/app/laborOrder/assignmechanic`, { laborOrderId, workerId });
  }

  unassignMechanic(orderId: string, workerId: number){
    return this.http.delete<any>(`${this.apiUrl}/app/laborOrder/${orderId}/mechanicAssignment/${workerId}`);
  }

  searchMechanics(query: string) {
    let queryParam: string = query.match(/^\d/) ? `CardNumber=${query}` : `SearchQ=${query}`;
    return this.http.get<any>(`${this.apiUrl}/app/laborOrder/searchMechanic?${queryParam}`);
  }

  setCommentAssignment(laborOrderId: string, workerId: number, comments: string) {
    return this.http.post<any>(`${this.apiUrl}/app/laborOrder/commentMechanic`, { laborOrderId, workerId, comments });
  }
}